<template>
  <div class="transfer-page">
    <SimpleHeader></SimpleHeader>
    <div class="ml">
      <el-button type="text" class="el-icon-back fs16" @click="$router.go(-1)"> {{ $t(207) }}</el-button>
    </div>
    <div class="fund-box">
      <h3 class="title">{{ $t(232) }}</h3>
      <div class="flex">
        <el-form
          class="flex1"
          ref="form"
          :rules="rules"
          :model="form">
          <div class="item mt20 mb20">
            <div class="label mb5">{{ $t(237) }}</div>
            <div class="transfer border flex align-center">
              <div class="line"></div>
              <div class="dir-label mr10">
                <div class="dir">{{ $t(239) }}</div>
                <div class="dir-to">{{ $t(240) }}</div>
              </div>
              <div class=" flex1 cate  mr10">
                <div class="from" :class="{'transfering': transfering}">
                  <el-dropdown @command="handleCommandFrom" trigger="click">
                    <span class="el-dropdown-link cursor">
                      {{ fromAccount.category == 'FUTURE' && changeText ? $t('nav3_3') : fromAccount.categoryName }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="item in accountList" :key="item.categoryName" :command="item">{{ item.category == 'FUTURE' && changeText ? $t('nav3_3') : item.categoryName }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="to" :class="{'transfering': transfering}">
                  <el-dropdown @command="handleCommandTo" trigger="click">
                    <span class="el-dropdown-link cursor">
                      {{ toAccount.category == 'FUTURE' && changeText ? $t('nav3_3') : toAccount.categoryName }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="item in accountList" :key="item.categoryName" :command="item">{{ item.category == 'FUTURE' && changeText ? $t('nav3_3') : item.categoryName }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="switch-dir cursor el-icon-sort" @click="switchDir"></div>
            </div>
          </div>
          <div class="item coin-select mb20">
            <div class="label mb5">{{ $t(196) }}</div>
            <img class="curCoinLogo" :src="coinMsg[form.coin]?coinMsg[form.coin].logoUri:''" alt="" style="margin-right: 10px;">
            <el-select v-model="form.coin" class="coin-input">
              <el-option
                v-for="item in coinList"
                :key="item.coin"
                :value="item.coin"
                :label="item.coin">
                <div class="flex align-center">
                  <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''" alt="" style="width: 24px;height:24px;margin-right: 10px;">
                  <span>{{ item.coin }}</span>
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="item amount mt20">
            <div class="label mb5">{{ $t(233) }}</div>
            <el-form-item prop="amount">
              <el-input v-model="form.amount"></el-input>
            </el-form-item>
            <div class="append">{{ form.coin }} | <span class="cursor" @click="allClick">{{ $t(216) }}</span></div>
            <div class="fs12 color999">{{ $t(236) }} {{ availableAmount }}{{ form.coin }}</div>
          </div>
          <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(238) }}</el-button></div>
        </el-form>
      </div>
    </div>
    <SimpleFooter></SimpleFooter>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import { transfer } from '@/api'
import utils from '@/utils/misc'
import { findIndex } from '@/utils/misc'

export default {
  name: 'Transfer',
  components: { SimpleHeader, SimpleFooter },
  mixins: [],
  data() {
    return {
      loading:false,
      form: {
        coin: 'USDT',
        fromCategory: '',
        toCategory: '',
        amount: ''
      },
      fromAccount: {},
      toAccount: {},
      fromCate: 'SPOT',
      toCate: 'FUTURE',
      transfering: false,
      transTimer: null
    }
  },
  watch: {
    accountList() {
      this.setAccount()
    }
  },
  computed: {
    changeText() {
      return [
        'c1241e1d-ad79-487b-b6f4-fb9d653ddf22', // test
        '3b042e72-f85f-4376-a138-ba1950da8fee', // prepro
        '7b89bc95-1758-46ef-8b9b-869892ee8f67', // res
        '9d28bfe3-6392-411e-b821-ede06d6354a5'
      ].includes(utils.getLs('agentIdentify'))
    },
    availableAmount() {
      if (!this.form.coin) {
        return '--'
      }
      for (const item of this.fromAccount.coins) {
        if (item.coin === this.form.coin) {
          return item.availableAmount
        }
      }
      return '--'
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    coinMsg() {
      return this.$store.state.coins
    },
    configObj() {
      return window.configObj
    },
    coinList() {
      if (this.toAccount.coins && this.fromAccount.coins) {
        const arr = []
        for (const item of this.toAccount.coins) {
          if (~findIndex(this.fromAccount.coins, coin => coin.name === item.name)) {
            arr.push(item)
          }
        }
        return arr
      }
      return []
    },
    accountList() {
      return this.$store.getters.assetsMap.assets
    },
    rules() {
      return {
        amount:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(209)))
            }
            if (isNaN(value) || +value <= 0 ) {
              return callback(new Error(this.$t(210)))
            }
            callback()
          },
          trigger: 'blur'
        }],
      }
    }
  },
  beforeMount() {
    this.fromCate = this.$route.query.from || this.fromCate
    this.toCate = this.$route.query.to || this.toCate
    this.setAccount()
  },
  methods: {
    allClick() {
      this.form.amount = this.availableAmount
    },
    setAccount() {
      this.fromAccount = this.accountList[this.fromCate] || {coins: []}
      this.toAccount = this.accountList[this.toCate] || {coins: []}
    },
    switchDir() {
      if (this.transfering) return
      this.transfering  = true
      this.transTimer = setTimeout(() => {
        const cate = this.toCate
        const _cate = this.fromCate
        this.toCate = _cate
        this.fromCate = cate
        this.setAccount()
        this.$nextTick(() => {
          this.transfering = false
        })
      }, 600)
    },
    handleCommandTo(command) {
      this.toCate = command.category
      this.toAccount = command
    },
    handleCommandFrom(command) {
      this.fromCate = command.category
      this.fromAccount = command
    },
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const obj = {coin: this.form.coin, fromCategory: this.fromAccount.category, toCategory: this.toAccount.category, amount: +this.form.amount}
          transfer(obj).then(res => {
            this.showSuccessMsg(this.$t(224))
            this.$store.dispatch('getUserAssets')
            this.$router.go(-1)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .transfer-page{
    min-height: 100vh;
    background-color: #fff;
  }
  .ml{
    width: 1000px;
    margin: 0 auto;
  }
  .fund-box{
    color: #1E2329;
    width: 1000px;
    border-radius: 8px;
    margin: 5vh auto;
    background: rgba(242,246,250,.5);
    padding: 40px 200px;
    .transfer{
      padding: 24px;
      background: #fff;
      border-radius: 6px;
      .line{
        position: relative;
        width: 1px;
        height: 76px;
        border-left: 1px dashed #ccc;
        margin-right: 12px;
        &:after,&:before{
          display: block;
          content: '';
          height: 8px;
          width: 8px;
          border-radius: 100%;
          position: absolute;
        }
        &::after{
          background: $warnColor;
          left: -4px;
          bottom: 0px;
        }
        &::before{
          background: $primaryColor;
          top: 0px;
          left: -4px;
        }
      }
      .to{
        height: 56px;
        &.transfering{
          transition: transform .6s;
          transform: translateY(-56px);
        }
      }
      .from{
        height: 56px;
        &.transfering{
          transition: transform .6s;
          transform: translateY(56px);
        }
      }
      .dir-label{
        margin-left: 8px;
        font-size: 12px;
        .dir-to,.dir{
          padding: 20px 0px;
        }
      }
      .cate{
        margin-left: 8px;
        .to, .from{
          padding: 20px 0px;
        }
      }
    }
    .switch-dir{
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: $primaryColor;
      color: #fff;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      margin-left: 20px;
    }
    .title{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .tab{
      margin: 10px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;
        &.active{
          background-color: #F5F5F5;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
    .coin-select{
      position: relative;
      .curCoinLogo{
        position: absolute;
        left: 10px;
        top: 28px;
        height: 24px;
        width: 24px;
        z-index:99;
      }
    }
  }
</style>
<style lang="scss">
.transfer-page{
  .coin-input {
    .el-input__inner{
      padding-left: 40px;
    }
  }
  .amount {
    position: relative;
    .append{
      position: absolute;
      right: 20px;
      top: 32px;
      height: 24px;
      line-height: 24px;
      z-index:99;
    }
    .fee{
      position: absolute;
      bottom: -20px;
      left: 0px;
    }
    .el-input__inner{
      padding-right: 80px;
    }
  }
}
</style>
